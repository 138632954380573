<template>
	<div>
		<div class="box">
			<img :src="imgUrl" alt="" width="100%">
		</div>

		<router-view></router-view>
		<footerBot />
	</div>
</template>

<script>
export default {
	name: '',
	created() { },
	mounted() {
		this.BannerImgFn()
	},
	computed: {
		// check: function () {
		// 	if(check){
		// 		console.log(1);
		// 	}else{
		// 		console.log(2);
		// 	}
		// 	return

		// }
	},
	data() {
		return {
			imgUrl:''
		}
	},
	methods: {
		BannerImgFn() {
			this.curlGet('/api/advert/detail', {
				mark: 'media_banner_01'
			}).then(res => {
				if (res.data.code) {
					this.imgUrl = res.data.data.image
				}
			})
		},
	},
}
</script>

<style lang="scss" scoped>
// @import '../scss/Media.scss';

.box {
	// background: url('../assets/media/mtfb-banner.jpg') no-repeat;
	background-size: 100%;
	min-height: 40vh;
}

.ct_box {
	width: 100% !important;
	background: #fff;
	margin: 0 auto;
	padding: 0 0 !important;
	position: relative;

}
</style>
